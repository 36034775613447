

































import {
  computed, defineComponent, PropType, ref,
} from '@nuxtjs/composition-api';

import ExternalLink from '~/components/common/ExternalLink.vue';
import RatingBars from '~/components/Product/components/RatingBars/RatingBars.vue';
import { useScrollPosition } from '~/composables';
import useProductPage from '~/composables/useProductPage';
import { REVIEW_URL } from '~/constants';
import { ProductInterface } from '~/modules/GraphQL/types';

import ReviewsList from './List/List.vue';

export default defineComponent({
  name: 'ProductTabReviews',
  components: { ExternalLink, RatingBars, ReviewsList },
  props: {
    product: {
      type: [Object, null] as PropType<ProductInterface | null>,
      default: null,
    },
  },

  setup(props) {
    const filterRating = ref<string>(null);

    const { reviews } = useProductPage();
    const { saveScrollPosition } = useScrollPosition();

    const currentRating = computed(() => {
      if (!props.product.rating_summary) {
        return 0;
      }

      return +((props.product.rating_summary * 5) / 100).toFixed(1);
    });

    const ratings = computed(() => ({
      stars: props.product.review_stars,
      total: props.product.review_count,
    }));
    const reviewUrl = computed(() => (`${REVIEW_URL}${props.product.id}`));

    const onFilterReviews = (rating: string) => {
      if (rating) {
        filterRating.value = rating;
        return;
      }

      filterRating.value = null;
    };

    return {
      currentRating,
      ratings,
      filterRating,
      reviews,
      reviewUrl,
      onFilterReviews,
      saveScrollPosition,
    };
  },
});
