import dayjs from 'dayjs';

import { useMagentoConfiguration } from '~/composables';
import isServer from '~/helpers/isServer';

import { UseFormatDateInterface } from './useFormatDate';

export function useFormatDate(): UseFormatDateInterface {
  const {
    selectedLocale,
  } = useMagentoConfiguration();

  const separatorIndex = selectedLocale.value?.indexOf('-');
  const languageCode = (separatorIndex > -1) ? selectedLocale.value.slice(0, Math.max(0, separatorIndex)) : selectedLocale.value;

  const isStoreLocale = selectedLocale.value ? dayjs().locale(selectedLocale.value)?.locale() : dayjs().locale(selectedLocale.value);
  const isLanguageLocale = languageCode ? dayjs().locale(languageCode)?.locale() : languageCode;
  const locale = isStoreLocale || isLanguageLocale;

  const formatDate = (date: string, format: string) => {
    if (isServer) {
      return date;
    }

    if (locale && languageCode) {
      return dayjs(date).locale(languageCode).format(format);
    }

    return dayjs(date).format(format);
  };

  return {
    formatDate,
  };
}

export default useFormatDate;
