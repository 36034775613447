




























































































import {
  computed, defineComponent, PropType, ref,
} from '@nuxtjs/composition-api';

import SvgImage from '~/components/General/SvgImage.vue';
import RatingStars from '~/components/RatingStars/RatingStars.vue';
import useFormatDate from '~/composables/useFormatDate';
import { ProductReview } from '~/modules/GraphQL/types';

const MAX_REVIEW_NUMBER = 5;

const NAV_ACTIONS = {
  prev: 'prevPage',
  next: 'nextPage',
  first: 'firstPage',
  last: 'lastPage',
  goTo: 'goToPage',
};

export default defineComponent({
  name: 'ReviewsList',
  components: {
    RatingStars,
    SvgImage,
  },
  props: {
    perPage: {
      type: Number,
      required: false,
      default: 5,
    },
    items: {
      type: Array as PropType<ProductReview[]>,
      default: () => [],
    },
    filterRating: {
      type: [String, Number],
      default: null,
    },
  },

  setup(props) {
    const currentPage = ref<number>(1);
    const { formatDate } = useFormatDate();

    const filteredReviews = computed(() => (props.filterRating
      ? props.items.filter((item) => item.ratings_breakdown[0]?.value === props.filterRating)
      : props.items));
    const reviewsCount = computed(() => filteredReviews.value?.length || 0);

    const reviews = computed(() => {
      const start = (currentPage.value - 1) * props.perPage;
      const end = start + props.perPage;

      return filteredReviews.value.slice(start, end);
    });

    const pageCount = computed(() => Math.floor(reviewsCount.value / props.perPage) + Math.min(1, reviewsCount.value % props.perPage));
    const pageList = computed(() => {
      const pages = [];

      if (pageCount.value <= MAX_REVIEW_NUMBER || currentPage.value === 1 || currentPage.value === 2) {
        for (let i = 1; i <= Math.min(pageCount.value, MAX_REVIEW_NUMBER); i += 1) {
          pages.push(i);
        }

        return pages;
      }

      if (currentPage.value === pageCount.value || currentPage.value === pageCount.value - 1) {
        for (let i = pageCount.value; i >= 1 && i >= pageCount.value - 4; i -= 1) {
          pages.unshift(i);
        }

        return pages;
      }

      return [currentPage.value - 2, currentPage.value - 1, currentPage.value, currentPage.value + 1, currentPage.value + 2];
    });

    const navActions = (action: string, pageNumber?: number) => {
      switch (action) {
        case NAV_ACTIONS.prev:
          currentPage.value = Math.max(1, currentPage.value - 1);
          break;
        case NAV_ACTIONS.next:
          currentPage.value = Math.max(1, currentPage.value + 1);
          break;
        case NAV_ACTIONS.first:
          currentPage.value = 1;
          break;
        case NAV_ACTIONS.last:
          currentPage.value = pageCount.value;
          break;
        case NAV_ACTIONS.goTo:
          currentPage.value = Math.max(1, Math.min(pageCount.value, pageNumber));
          break;
        default:
      }
    };

    const getFormattedDate = (date: string) => formatDate(date, 'D MMMM YYYY');

    return {
      currentPage,
      reviews,
      pageCount,
      navActions,
      pageList,
      getFormattedDate,
    };
  },
});
